import gql from 'graphql-tag'

export const eventById = gql`
	query eventById ($id: ID!) {
		Event(id: $id) {
			id
			plate
			pcLoggedAt
		}
	}
`

export const getCameraStreams = gql`
    query StreamUrl($id: ID!) {
      StreamUrl(id: $id) {
        url
        name
        signId
        gateId
        gateType
        error
      }
    }
`

export const getGates = gql`
    query GatesForInstallation($id:ID!) {
          gatesForInstallation(installationId: $id) {
            id
            type
            gateDescription
        }
    }`


export const listEvents = gql`
	query listEvents (
		$installationId: ID!
		$offset: Int!
		$search: String
	) {
		allBookableEvents(
			filter: {
				installationId: $installationId
				plate_contains: $search
			}
			orderBy: pcLoggedAt_DESC
			skip: $offset
			first: 20
		) {
			id
			plate
			gateDescription
			pcLoggedAt
		}
		_allBookableEventsMeta(
			filter: {
				installationId: $installationId
				plate_contains: $search
			}
		) {
			count
		}
	}
`

export const allActiveRateCards = gql`
	query allActiveRateCards ($installationId: ID) {
		allRateCards(
			filter: {
				installationId: $installationId
				isArchived: false
			}
			orderBy: name_ASC
		) {
			id
			lineageId
			version

			name
			type
			calculationMode
			serviceFeePercent

			entryAfterTime
			exitBeforeTime
			bookingRateAudPerDay
			overstayRateAudPerHour
			overstayFlagfallAud
			overstayGraceMinutes

			bookingRateAudPerHour
			chargeOnExpiryAud

			hoursUntilExpiry
			maximum24HourAud

			guestName
			guestEmail
			guestPhone
			guestRoom
			guestReservation
			paymentStripe
			includeCarType,
			includeCarNotes,
			includeArrivalTime,
			includeLeavingTime,

			isArchived
			createdAt
		}
	}
`

export const listBookings = gql`
	query listBookings (
		$installationId: ID!
		$offset: Int!
		$search: String
		$status: BookingStatusEnum
	) {
		allBookings (
			filter: {
				installationId: $installationId
				label_contains: $search
				status: $status
      			deletedAt: null
			}
			orderBy: createdAt_DESC
			skip: $offset
			first: 20
		) {
			id
			status
			plate

			startDay
			endDay

			guestName

			rateCard {
				name
				version
				type
			}
			bookingExtras {
				id
			}
		}
		_allBookingsMeta(
			filter: {
				installationId: $installationId
				label_contains: $search
				status: $status
      			deletedAt: null
			}
		) {
			count
		}
	}
`


export const listBookingsWithExtras = gql`
	query Extras($installationId: ID!) {
		bookingsWithExtras(installationId: $installationId) {
			id
			status
			plate
			startDay
			endDay
			guestName
			rateCard {
				name
				version
				type
			}
			bookingExtras {
				rateCardExtra {
					amount
					description
				}
			}
		}
	}
`

export const listGateCharges = gql`
    query QueryInstallationCharges($input: QueryChargeByInstallationInput!) {
      getGateChargesForInstallation(input: $input) {
        id
        createdAt    
        plate
        approved
        amount
        interval
      }
}
`

export const bookingById = gql`
	query bookingById ($bookingId: ID!) {
		Booking (id: $bookingId) {
			id
			status
			installationId
			plate
			staffName
			guestName
			guestEmail
			guestPhone
			guestRoom
			guestReservation
			stripeSource
			stripeCustomerId
			carType
			carNotes

			startDay
			endDay

			createdAt
			closedAt

			rateCard {
				id
				lineageId
				version

				name
				type
				calculationMode
				serviceFeePercent

				entryAfterTime
				exitBeforeTime
				bookingRateAudPerDay
				overstayRateAudPerHour
				overstayFlagfallAud
				overstayGraceMinutes

				bookingRateAudPerHour
				chargeOnExpiryAud

				hoursUntilExpiry
				maximum24HourAud

				guestName
				guestEmail
				guestPhone
				guestRoom
				guestReservation
				paymentStripe
				includeCarType
				includeCarNotes
				includeArrivalTime
				includeLeavingTime

				isArchived
				createdAt
			}

			charges (orderBy: createdAt_DESC) {
				amountAud
				error
				createdAt
				finalisedAt
			}

			emails {
				id
				templateClass
				mailgunId
				finalisedAt
			}

			events {
				gateType
        		gateDescription
				pcLoggedAt
			}
			
			bookingExtras {
				rateCardExtra {
					description
					type
					amount
				}
			}

			refunds (orderBy: createdAt_ASC) {
				id
				status
				amountAud
				createdAt
				createdBy {
					name
				}
				updatedAt
			}

			cancellationRequests {
				id
				isProcessed
			}
		}
	}
`

export const getStripePaymentMethods = gql`
	query GetStripePaymentMethods($customerId: String!) {
		getStripePaymentMethods(customerId: $customerId) {
			card {
				brand
				last4
				exp_month
				exp_year
			}
		}
	}
`
