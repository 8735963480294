import React, { useRef, useState } from "react";
import { Alert } from "../../../components/Alert";
import { Button, NakedButton } from "../../../components/Button";
import { CheckboxInput } from "../../../components/CheckboxInput";
import {
  Container,
  ColContainer,
  TPL_THIRDS,
} from "../../../components/Container";
import { LoadingError } from "../../../components/LoadingFork";
import { HR, H3 } from "../../../components/Typography";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { SelectInput } from "../../../components/SelectInput";
import { Table, THead, TBody } from "../../../components/Table";
import { css } from "glamor";
import { useFormState } from "react-use-form-state";
import { useMutation, useQuery } from "@apollo/client";
import { withAppConfig } from "../../../components/AppConfigContext";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import QRCode from "react-qr-code";

import * as enums from "../../../lib/enums";
import {
  determineOptionals,
  formatCurrency,
  formatHours,
} from "../../../lib/rateCardUtils";
import { createRateCardMutation, createRateCardExtraMutation } from "./queries";
import * as theme from "../../../theme";
import { flavours } from "../../../theme";
import { RateCardExtraContainer } from "../../../components/RateCardExtras";
import { DateInput } from "../../../components/DateInput";
import { allLots } from "../ParkingLots/queries";

const CF_CSS = css({
  display: "flex",
  alignItems: "center",
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#FAFAFA",
  borderRadius: 6,
  fontSize: 22,
  fontWeight: "bold",
  height: 60,
  paddingLeft: 16,
  paddingRight: 16,
  marginBottom: 16,
});

function CurrencyField({ label, value, currency }) {
  value = Number.isFinite(value) ? formatCurrency(value, currency) : "0.00";
  return (
    <div>
      {label && <Label>{label}</Label>}
      <div className={CF_CSS}>{value}</div>
    </div>
  );
}

function validateAUD(value) {
  return isFinite(value) ? undefined : "Please enter a number";
}

function DefaultExitInfo() {
  return (
    <>
      <p>
        The exiting guest card is the guest card used by default for pay-at-gate
        bookings for supported installations.
      </p>
      <p>
        Bookings created in this way still appear in the bookings list as usual
        and can be updated by hotel staff as required.
      </p>
    </>
  );
}

function PublicConsoleInfo({ url, secondaryUrl }) {
  return (
    <>
      <p>
        The public guest card allows guests to create their own 1-day parking
        bookings through the <a href={url}>public parking console</a>. Guests
        will enter their details and optionally receive a booking confirmation
        and payment receipt by email.
      </p>
      { secondaryUrl &&
        <p>
          Hotel 57 <a href={secondaryUrl}>public parking console</a>.
        </p>
      }
      <p>
        Bookings created in this way still appear in the bookings list as usual
        and can be updated by hotel staff as required.
      </p>
    </>
  );
}

const ALERT_CSS = css({paddingTop: 24});

function DefaultExitPrompt({onChange, value, disabled}) {
  if (value) {
    return (
      <Alert flavour="info" className={ALERT_CSS}>
        <strong>
          This guest card will be set as the default Guest Card for guests
          exiting without a booking.
        </strong>
        <DefaultExitInfo />
        <Button
          flavour="danger"
          spaceBelow="none"
          onClick={() => onChange(false)}
          disabled={disabled}
        >
          Unset as the default Exiting Guest Card
        </Button>
      </Alert>
    );
  }

  return (
    <Alert flavour="warning" className={ALERT_CSS}>
      <strong>Change the default Exiting Guest Card</strong>
      <DefaultExitInfo />
      <Button
        spaceBelow="none"
        onClick={() => onChange(true)}
        disabled={disabled}
      >
        Set as the default Exiting Guest Card
      </Button>
    </Alert>
  );
}

function _DefaultPublicPrompt({
  appConfig,
  installation,
  onChange,
  value,
  disabled,
  rateCard,
}) {
  const publicConsoleUrl = `${appConfig.PUBLIC_CONSOLE_URL}/${installation.publicConsoleSlug}${rateCard?.publicUrl ? `/${rateCard.publicUrl}` : ''}`;
  let hotel57PublicConsoleUrl = null;
  if (installation?.brandingKey === 'rydges-sydney-central') {
    hotel57PublicConsoleUrl = `${appConfig.PUBLIC_CONSOLE_URL}/${installation.publicConsoleSlug}${rateCard?.publicUrl ? `/${rateCard.publicUrl}?h57=t` : '?h57=t'}`;
  }

  if (value) {
    return (
      <Alert flavour="info" className={ALERT_CSS}>
        <strong>
          This guest card will be set as the default Public Guest Card.
        </strong>
        <PublicConsoleInfo url={publicConsoleUrl} secondaryUrl={hotel57PublicConsoleUrl} />
        <Button
          flavour="danger"
          spaceBelow="none"
          onClick={() => onChange(false)}
          disabled={disabled}
        >
          Unset as the default Public Guest Card
        </Button>
      </Alert>
    );
  }

  return (
    <Alert flavour="warning" className={ALERT_CSS}>
      <strong>Change the default Public Guest Card</strong>
      <PublicConsoleInfo url={publicConsoleUrl} secondaryUrl={hotel57PublicConsoleUrl} />
      <Button
        spaceBelow="none"
        onClick={() => onChange(true)}
        disabled={disabled}
      >
        Set as the default Public Guest Card
      </Button>
    </Alert>
  );
}

const DefaultPublicPrompt = withAppConfig(_DefaultPublicPrompt);

export function Triple({
  currency,
  serviceFeeRatio,
  type,
  disabled,
  error,
  onChange,
  value,
}) {
  const serviceFee = serviceFeeRatio * Number(value);
  const baseRate = Number(value) - serviceFee;

  return (
    <ColContainer columnTemplate={TPL_THIRDS}>
      <Input
        required
        label="Charge to Guest"
        type={type}
        disabled={disabled}
        error={error}
        onChange={onChange}
        value={value}
      />
      <CurrencyField label="Service Fee" value={serviceFee} currency={currency} />
      <CurrencyField label="Base Rate" value={baseRate} currency={currency} />
    </ColContainer>
  );
}

const ALIGN_LEFT = css({ textAlign: "left !important" });
const ALIGN_RIGHT = css({ textAlign: "right !important" });

function FieldRow({ name, ...props }) {
  return (
    <tr>
      <td>
        <span className={ALIGN_LEFT}>{name}</span>
      </td>
      <td className={ALIGN_RIGHT}>
        <CheckboxInput {...props} />
      </td>
    </tr>
  );
}

function TextInputRow({ name, ...props }) {
  return (
    <tr>
      <td>
        <span className={ALIGN_LEFT}>{name}</span>
      </td>
      <td className={ALIGN_RIGHT}>
        <Input {...props} />
      </td>
    </tr>
  );
}

function TextAreaRow({ name, ...props }) {
  return (
    <tr>
      <td>
        <span className={ALIGN_LEFT}>{name}</span>
      </td>
      <td className={ALIGN_RIGHT}>
        <textarea
          className={css({
            display: "block",
            width: "100%",
            background: theme.white,
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "#EAEDEF",
            borderRadius: "6px",
            fontSize: "18px",
            transition: theme.transitionSnappy,
            ":focus": {
              outline: "none",
              borderColor: theme.blue,
            },
            ":disabled": {
              opacity: 0.5,
            },
            "::placeholder": {
              color: theme.gray20,
            },
          })}
          {...props}
        />
      </td>
    </tr>
  );
}

export default function DetailsForm({
  installation,
  rateCard,
  rateCardStatus,
  onSubmit,
  onCancel,
}) {
  const [{ errors, values }, { text, checkbox, select, raw, number, email }] =
    useFormState({
      name: rateCard ? rateCard.name : "",
      type: rateCard ? rateCard.type : "DAILY",
      calculationMode: rateCard?.calculationMode || "SINGLE_DAY",
      defaultOnExit: rateCard ? rateCard.defaultOnExit : false,
      defaultPublic: rateCard ? rateCard.defaultPublic : false,
      isPublic: rateCard ? rateCard.isPublic : false,
      selfPark: rateCard ? rateCard.selfPark : false,
      publicUrl: rateCard ? rateCard.publicUrl : "",
      stopPublicBookingWhenLotFull: rateCard
        ? rateCard.stopPublicBookingWhenLotFull
        : false,
      lotFullText: rateCard ? rateCard.lotFullText : "",
      homeText: rateCard ? rateCard.homeText : "",

      // Daily
      entryAfterTime: rateCard ? rateCard.entryAfterTime : 600, // 10am
      exitBeforeTime: rateCard ? rateCard.exitBeforeTime : 600, // 10am
      bookingRateAudPerDay: rateCard ? rateCard.bookingRateAudPerDay : "0",
      overstayFlagfallAud: rateCard ? rateCard.overstayFlagfallAud : "0",
      overstayRateAudPerHour: rateCard ? rateCard.overstayRateAudPerHour : "0",
      overstayGraceMinutes: rateCard ? rateCard.overstayGraceMinutes : 15, // 15 minutes

      // Hourly
      bookingRateAudPerHour: rateCard ? rateCard.bookingRateAudPerHour : "0",
      chargeOnExpiryAud: rateCard ? rateCard.chargeOnExpiryAud : "0",

      // Mixed
      maximum24HourAud: rateCard ? rateCard.maximum24HourAud : "0",
      hoursUntilExpiry: rateCard ? rateCard.hoursUntilExpiry : 120, // 5 days

      // Guest fields
      guestName: rateCard ? rateCard.guestName : true,
      guestEmail: rateCard ? rateCard.guestEmail : false,
      guestPhone: rateCard ? rateCard.guestPhone : false,
      guestRoom: rateCard ? rateCard.guestRoom : false,
      guestReservation: rateCard ? rateCard.guestReservation : false,
      paymentGate: rateCard ? rateCard.paymentGate : false,
      multiDates: rateCard ? rateCard.multiDates : false,
      paymentStripe: rateCard ? rateCard.paymentStripe : true,
      startsAt: rateCard ? rateCard.startsAt : null,
      expiresAt: rateCard ? rateCard.expiresAt : null,
      bookingsLimit: rateCard ? rateCard.bookingsLimit : null,
      lotId: rateCard ? rateCard.lotId : null,
      rateCardExtras:
        rateCard && rateCard.rateCardExtras ? rateCard.rateCardExtras : [],
      includeCarType: rateCard ? rateCard.includeCarType : false,
      includeCarNotes: rateCard ? rateCard.includeCarNotes : false,
      includeArrivalTime: rateCard ? rateCard.includeArrivalTime : false,
      includeLeavingTime: rateCard ? rateCard.includeLeavingTime : false,

      isVip: rateCard ? rateCard.isVip : false,
      vipEmailRecipient: rateCard ? rateCard.vipEmailRecipient : "",
    });
  
  if (values.type === "DAILY") {
    if (values.multiDates && values.calculationMode.includes("SINGLE")) {
      values.calculationMode = "MULTI_DATE_OVERNIGHT"
    }
  
    if (!values.multiDates && values.calculationMode.includes("MULTI")) {
      values.calculationMode = "SINGLE_DAY"
    }
  }

  const [createRateCard, { error: submitError }] = useMutation(
    createRateCardMutation,
  );
  const [createRateCardExtra, { error: submitRateCardExtraError }] =
    useMutation(createRateCardExtraMutation);
  const {
    data: lots,
    loading: lotsLoading,
    error,
  } = useQuery(allLots, {
    variables: {
      installationId: installation.id,
    },
  });

  const [rateCardExtras, setRateCardExtras] = useState(
    rateCard && rateCard.rateCardExtras ? rateCard.rateCardExtras : []
  );
  const lotsOptions = lots ? lots.allLots : [];

  async function _onSubmit() {
    values.entryAfterTime = parseInt(values.entryAfterTime);
    values.exitBeforeTime = parseInt(values.exitBeforeTime);
    values.overstayGraceMinutes = parseInt(values.overstayGraceMinutes);
    values.hoursUntilExpiry = parseInt(values.hoursUntilExpiry);
    values.bookingsLimit = parseInt(values.bookingsLimit);
    // ensure hourly rateCard will be created with LEGACY calculation mode
    if (values.type === 'HOURLY') values.calculationMode = 'LEGACY'
    const variables = { installationId: installation.id, ...values };
    if (rateCard) {
      Object.assign(
        variables,
        {
          lineageId: rateCard.lineageId,
          lotId: rateCard.lot ? rateCard.lot.id : null,
        });
    }

    const { data } = await createRateCard({ variables });
    console.log("data", data);
    if (rateCardExtras.length > 0) {
      for (let rateCardExtra of rateCardExtras) {
        await createRateCardExtra({
          variables: { rateCardId: data.createRateCard.id, ...rateCardExtra },
        });
      }
    }
    onSubmit(data.createRateCard);
  }

  const isCreating = !rateCard;
  const hasErrors = Object.values(errors).some((x) => x);
  const noEditing = rateCard && rateCard.isArchived;
  const canSubmit = !hasErrors && !noEditing;
  const { serviceFeePercent } = rateCard || installation;
  const isDaily = values.type === "DAILY";
  const optionals = determineOptionals(values);
  const { currency } = installation;

  const dataRows = [
    { name: "Guest Name", key: "guestName" },
    { name: "Guest Email", key: "guestEmail" },
    { name: "Guest Phone", key: "guestPhone" },
    { name: "Guest Room", key: "guestRoom" },
    { name: "Guest Reservation", key: "guestReservation" },
    { name: "Gate Payment", key: "paymentGate" },
    { name: "Stripe Payment", key: "paymentStripe" },
  ].map(({ name, key }) => {
    const disabled = optionals[key] === false;
    return (
      <FieldRow
        key={key}
        {...checkbox(key)}
        name={name}
        disabled={noEditing || disabled}
      />
    );
  });

  const serviceFeeRatio = Number(serviceFeePercent) / 100;

  const [qrcode, setQrcode] = useState(false);
  const [isHotel57, setIsHotel57] = useState(false);
  const componentRef = useRef();

  async function handlePrintFn(handlePrint, hotel57 = false) {
    if (hotel57) await setIsHotel57(true);
    await setQrcode(true);
    handlePrint();
  }

  return (
    <div>
      {submitError && <LoadingError error={submitError} />}
      {hasErrors && <LoadingError error={Object.values(errors)[0]} />}

      {isCreating ? <H3>Guest Card Details</H3> : null}
      {isCreating ? (
        <ColContainer columnTemplate={TPL_THIRDS}>
          <Input label="Label" {...text("name")} required />
          <SelectInput
            label="Type"
            options={enums.RATECARD_TYPE_OPTIONS}
            error={errors.type}
            {...select("type")}
            disabled={noEditing}
          />
          {!lotsLoading && (
            <SelectInput
              label="Lot"
              options={[
                { label: "Default", value: null },
                ...lotsOptions.map((l) => {
                  return {
                    value: l.id,
                    label: `${l.name} (${l.description})`,
                  };
                }),
              ]}
              error={errors.type}
              {...select("lotId")}
              disabled={noEditing}
            />
          )}
        </ColContainer>
      ) : null}
      <HR spaceAbove="medium" spaceBelow="medium" />

      {isDaily ? (
        <>
          <H3>Daily rate (per day)</H3>
          <Triple
            currency={currency}
            serviceFeeRatio={serviceFeeRatio}
            error={errors.bookingRateAudPerDay}
            {...text({ name: "bookingRateAudPerDay", validate: validateAUD })}
            disabled={noEditing}
          />

          <HR spaceAbove="medium" spaceBelow="medium" />
          <H3>Overstay flagfall (flat fee)</H3>
          <Triple
            currency={currency}
            serviceFeeRatio={serviceFeeRatio}
            error={errors.overstayFlagfallAud}
            {...text({ name: "overstayFlagfallAud", validate: validateAUD })}
            disabled={noEditing}
          />

          <HR spaceAbove="medium" spaceBelow="medium" />
          <H3>Overstay rate (per hour)</H3>
          <Triple
            currency={currency}
            serviceFeeRatio={serviceFeeRatio}
            error={errors.overstayRateAudPerHour}
            {...text({
              name: "overstayRateAudPerHour",
              validate: validateAUD,
            })}
            disabled={noEditing}
          />
        </>
      ) : (
        <>
          <H3>Hourly rate (per hour)</H3>
          <Triple
            currency={currency}
            serviceFeeRatio={serviceFeeRatio}
            error={errors.bookingRateAudPerDay}
            {...text({
              name: "bookingRateAudPerHour",
              validate: validateAUD,
            })}
            disabled={noEditing}
          />
        </>
      )}

      <HR spaceAbove="medium" spaceBelow="medium" />
      {isDaily ? (
        <H3>Overstay maximum charge (per day of overstay)</H3>
      ) : (
        <H3>Maximum charge (per 24 hours)</H3>
      )}
      <Triple
        currency={currency}
        serviceFeeRatio={serviceFeeRatio}
        error={errors.maximum24HourAud}
        {...text({ name: "maximum24HourAud", validate: validateAUD })}
        disabled={noEditing}
      />

      <HR spaceAbove="medium" spaceBelow="medium" />
      <H3>Schedules</H3>
      {isDaily ? (
        <>
          <ColContainer columnTemplate={TPL_THIRDS}>
            <SelectInput
              label="Entry after time"
              options={enums.ENTRY_EXIT_24HR_OPTIONS}
              error={errors.entryAfterTime}
              {...select("entryAfterTime")}
              disabled={noEditing}
            />
            <SelectInput
              label="Exit before time"
              error={errors.exitBeforeTime}
              options={enums.ENTRY_EXIT_24HR_OPTIONS}
              {...select("exitBeforeTime")}
              disabled={noEditing}
            />
            <SelectInput
              label="Grace period after booking"
              options={enums.GRACE_PERIOD_OPTIONS}
              error={errors.overstayGraceMinutes}
              {...select("overstayGraceMinutes")}
              disabled={noEditing}
            />
          </ColContainer>
        </>
      ) : null}
      <ColContainer columnTemplate={TPL_THIRDS}>
        <SelectInput
          label={isDaily ? "Maximum overstay period" : "Expires after"}
          options={enums.EXPIRY_HOURS_OPTIONS}
          error={errors.hoursUntilExpiry}
          {...select("hoursUntilExpiry")}
          disabled={noEditing}
        />
      </ColContainer>

      {isDaily ? null : (
        <>
          <HR spaceAbove="medium" spaceBelow="medium" />
          <H3>
            Charge after {formatHours(values.hoursUntilExpiry)} with no exit
            (expiry)
          </H3>
          <Triple
            currency={currency}
            serviceFeeRatio={serviceFeeRatio}
            error={errors.chargeOnExpiryAud}
            {...text({ name: "chargeOnExpiryAud", validate: validateAUD })}
            disabled={noEditing}
          />
        </>
      )}
      <H3>Rate Card Limits</H3>

      <Table>
        <TBody>
          {" "}
          <DateInput
            floatPicker
            label="Starts At"
            placeholder="Enter start date"
            autoComplete="off"
            displayFormat="ddd, D MMM YYYY"
            isOutsideRange={(x) => false}
            type="text"
            {...raw({
              name: "startsAt",
            })}
          />
          <DateInput
            floatPicker
            label="Expires At"
            placeholder="Enter expiry date"
            autoComplete="off"
            displayFormat="ddd, D MMM YYYY"
            isOutsideRange={(x) => false}
            type="text"
            {...raw({
              name: "expiresAt",
            })}
          />
          <Input
            label="Bookings Limit"
            disabled={false}
            {...number({ name: "bookingsLimit" })}
          />
          {rateCardStatus != null && rateCardStatus.bookings != null && (
            <p
              style={{
                fontSize: 12,
                color: "grey",
                marginTop: 0,
                lineHeight: 0,
              }}
            >
              Current Bookings: {rateCardStatus.bookings}
            </p>
          )}
        </TBody>
      </Table>

      <H3>Public Rate Card Info</H3>
      <Table>
        <TBody>
          <FieldRow
            key={"isPublic"}
            {...checkbox("isPublic")}
            name={"Is Public"}
            disabled={false}
          />

          <FieldRow
            key={"multiDates"}
            {...checkbox("multiDates")}
            name={"Multi-Dates (multi-day and future bookings)"}
            disabled={false}
          />

          <FieldRow
            key={"selfPark"}
            {...checkbox("selfPark")}
            name={"Include Self Park flow"}
            disabled={false}
          />

          <FieldRow
            key={"includeCarType"}
            {...checkbox("includeCarType")}
            name={"Include Car Type"}
            disabled={false}
          />

          <FieldRow
            key={"includeCarNotes"}
            {...checkbox("includeCarNotes")}
            name={"Include Car Notes"}
            disabled={false}
          />

          <FieldRow
            key={"includeArrivalTime"}
            {...checkbox("includeArrivalTime")}
            name={"Include Arrival Time"}
            disabled={false}
          />

          <FieldRow
            key={"includeLeavingTime"}
            {...checkbox("includeLeavingTime")}
            name={"Include Leaving Time"}
            disabled={false}
          />
          <tr>
            <td>
              Calculation Mode
            </td>
            <td>
              <SelectInput
                options={enums.getRateCardCalculationModes(isDaily, values.multiDates, rateCard?.calculationMode)}
                error={errors.calculationMode}
                {...select("calculationMode")}
              />
            </td>
          </tr>
          <TextInputRow
            key={"publicUrl"}
            {...text("publicUrl")}
            rows={2}
            name={"Public Url"}
          />
          <TextAreaRow
            {...text("homeText")}
            rows="3"
            cols="30"
            key={"homeText"}
            name={"Home page text (multi line)"}
          />

          <TextAreaRow
            {...text("lotFullText")}
            rows="3"
            cols="30"
            key={"lotFullText"}
            name={"Message for when lot is full (multi line)"}
          />

          <FieldRow
            key={"stopPublicBookingWhenLotFull"}
            {...checkbox("stopPublicBookingWhenLotFull")}
            name={"Stop public bookings when lot full"}
            disabled={false}
          />
        </TBody>
      </Table>

      {rateCard ? (
        <ReactToPrint
          content={() => componentRef.current}
          onAfterPrint={() => {
            setQrcode(false);
            setIsHotel57(false);
          }}
        >
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <>
                <Button onClick={() => handlePrintFn(handlePrint)}>
                  Print QR code
                </Button>
                { installation?.brandingKey === 'rydges-sydney-central' &&
                  <Button onClick={() => handlePrintFn(handlePrint, true)} style={{marginLeft: '1rem'}}>
                    Print 57Hotel QR code
                  </Button>
                }
              </>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      ) : null}

      <RateCardExtraContainer
        rateCardExtras={rateCardExtras}
        setRateCardExtras={setRateCardExtras}
      />

      {qrcode ? (
        <div ref={componentRef}>
          <Table>
            <THead>
              <tr>
                <h2
                  className={css({
                    textAlign: "center",
                    paddingTop: "60px",
                    fontFamily: "monospace",
                    marginBottom: "60px",
                  })}
                >
                  {rateCard.name}
                </h2>
              </tr>
            </THead>
            <TBody
              className={css({
                textAlign: "center",
                fontFamily: "monospace",
              })}
            >
              <QRCode
                value={`https://public.hotelparking.com.au/${installation.publicConsoleSlug}/${rateCard.publicUrl}${isHotel57 ? '?h57=t' : ''}`}
              />
              <br />
              <h3>book parking on this link: </h3>
              <h3>
                https://public.hotelparking.com.au/
                {installation.publicConsoleSlug}/{rateCard.publicUrl}${isHotel57 ? '?h57=t' : ''}{" "}
              </h3>
            </TBody>
          </Table>
        </div>
      ) : null}

      <HR spaceAbove="medium" spaceBelow="medium" />
      <H3>Required Fields</H3>
      <Container>
        <Table>
          <THead>
            <tr>
              <th className={ALIGN_LEFT}>Field</th>
              <th className={ALIGN_RIGHT}>Required</th>
            </tr>
          </THead>
          <colgroup>
            <col className={css({ width: "80%" })} />
            <col className={css({ width: "20%" })} />
          </colgroup>
          <TBody>{dataRows}</TBody>
        </Table>
      </Container>

      <Container spaceAbove="medium" spaceBelow="medium">
        <H3>VIP Guest Card</H3>
        <Table>
          <TBody>
            <FieldRow
              key={"isVip"}
              {...checkbox("isVip")}
              name={"Is VIP"}
              disabled={false}
            />

            <TextInputRow
              key={"vipEmailRecipient"}
              {...email("vipEmailRecipient")}
              rows={2}
              name={"Email Recipient"}
              placeholder="name@email.com"
              error={errors.vipEmailRecipient}
            />
          </TBody>
        </Table>
      </Container>

      <Container spaceAbove="medium">
        <H3 id="public-ratecard">Public Guest Card</H3>
        <DefaultPublicPrompt
          installation={installation}
          rateCard={rateCard}
          {...raw("defaultPublic")}
          disabled={noEditing || !optionals.defaultPublic}
        />
      </Container>

      <Container spaceAbove="medium">
        <H3 id="public-ratecard">Exiting Guest Card</H3>
        <DefaultExitPrompt
          {...raw("defaultOnExit")}
          disabled={noEditing || !optionals.defaultOnExit}
        />
      </Container>

      <HR spaceAbove="medium" spaceBelow="medium" />
      <Container>
        <Button block type="submit" disabled={!canSubmit} onClick={_onSubmit}>
          {isCreating ? "Create" : "Update card"}
        </Button>{" "}
        <NakedButton block flavour="gray" onClick={onCancel}>
          Cancel
        </NakedButton>
      </Container>
    </div>
  );
}
